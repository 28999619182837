.faq__unit--3b-f1 {
  flex: 1 1 100%;
  border-bottom: 1px solid hsla(0, 0%, 93%, 1); }
  @media (max-width: 989px) {
    .faq__unit--last--zcvXh {
      border-bottom: 0 none; } }

.faq__unit-header--38Tqy {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0); }

.faq__unit-header-arrow-container--p2Leo {
  display: flex;
  justify-content: flex-end; }

.faq__unit-header-arrow--3XfiH {
  --svg-primary-color: hsla(0, 0%, 65%, 1); }
  .faq__unit-header-arrow--active--3fjGY svg {
    transform: rotate(180deg);
    --svg-primary-color: hsla(0, 0%, 15%, 1); }
  .faq__unit-header-arrow--3XfiH svg {
    transition: all 0.5s ease 0s; }

.faq__unit-content--KMSkj {
  display: none; }
  .faq__unit-content--visible--2dd9V {
    display: block; }

.faq__all-link-container--25Swr {
  display: flex;
  flex-direction: column;
  align-items: center; }
